
ion-alert {
    z-index: 9999999999 !important; 
}

.container {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0; 
    bottom: 0; 
    background-color: white;
    z-index: 99999;
}

.modal {
    width: 500px;
    height: 400px;
    background-color: #0c90b2;
    border-radius: 5px;
    margin: auto;
    height: 300px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -250px;
    margin-right: -250px;
    margin-top: -200px;
    padding: 10px;
}