.hmrc-payment {
  height: 100%;
  display: flex;
  width: 100%;
  margin: auto;
  background-color: #FAFAFC;
  overflow: auto;
  text-align: left;

  .strong-text {
    font-weight: 900;
  }

  .text-center {
    text-align: center;
  }


  .MuiAutocomplete-root {
    width: 100%;
  }

  .selected .MuiInputLabel-shrink {
    margin-left: -30px;
  }

  .bank-selector .MuiFormControl-root.MuiTextField-root {

    background: transparent;
    border: none;
    margin-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;

    .MuiFormLabel-root {
      color: #000000;
      font-size: .9rem;
    }

    .MuiInputLabel-shrink, .MuiIconButton-label {
      color: #3f90b0;
    }

    .MuiInput-underline:after {
      border: none;
    }

    .MuiInput-underline:before, .MuiInput-underline:hover:before, .MuiInput-underline:active:before {
      border: none;
    }
  }

  .item-inner {
    padding: 0;
  }

  .bank-selector-container {
    --padding-start: 0;
  }

  .display-inline-flex {
    display: inline-flex;
    width: 100%;
  }

  .display-flex {
    display: flex;
  }
  
  .display-none {
    display: none;
  }

  .input-container {
    --padding-start: 0;
    --inner-padding-end: 0;
    font-size: 14px;
    --background-hover: transparent;
    --highlight-color-focused: #3f90b0;
    --highlight-height: 0;
    --ion-color-primary: #3f90b0;

    ion-label {
      white-space: normal !important;
      padding-right: 30px;

      ion-icon, a ion-icon {
        position: absolute;
        bottom: 3px;
      }

      a ion-icon {
        bottom: 14px;
        margin-left: 10px;
      }
    }

    ion-select, .native-input {
      --padding-bottom: 0;
    }

    .in-item {
      margin: 0;
    }

    ion-checkbox {
      --border-width: 2px;
      --border-color: #3f90b0;
      --background-checked: #3f90b0;
      --border-color-checked: #3f90b0;
    }

    ion-select {
      --padding-start: 0;
      max-width: 100%;
      width: 100%;
    }
  }
}

.columns {
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  /*margin-top:20px;*/
  gap:12px;
}

.mt-4, .my-4 {
  margin-top: 1.5rem!important;
}

.money-input-prefix-container {
 
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  height:100%;
  width:30px;
}

.money-input-prefix {
  font-size: 14px;
  color: #2C2C2C;
  letter-spacing: 0.06px;
  line-height: 18px;
  padding-right:5px;
  padding-left:10px;
  border-right: 1px solid #DFE2E9;  
  flex: 1 1;
} 

.input-box {
  --padding-start: 0;
  --padding-top: 0;
    font-size: 14px;
    width: 100%;
    background-color: white;
    height: 40px;  
    padding-left: 35px;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    margin-right:10px;
   
}

.input-box:placeholder-shown {
  font-style: italic;
}

.display-inline-flex {
  display: inline-flex;
  width: 100%;
}

.display-flex {
  display: flex;
}

.display-none {
  display: none;
}
