.hmrc-payment {

  display: flex;
  margin: auto;
overflow: auto;
  text-align: left;
  max-width:600px;

  .strong-text {
    font-weight: 900;
  }

  .text-center {
    text-align: center;
  }


  .MuiAutocomplete-root {
    width: 100%;
  }

  .selected .MuiInputLabel-shrink {
    margin-left: -30px;
  }

  .bank-selector .MuiFormControl-root.MuiTextField-root {

    background: transparent;
    border: none;
    margin-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;

    .MuiFormLabel-root {
      color: #000000;
      font-size: .9rem;
    }

    .MuiInputLabel-shrink, .MuiIconButton-label {
      color: #3f90b0;
    }

    .MuiInput-underline:after {
      border: none;
    }

    .MuiInput-underline:before, .MuiInput-underline:hover:before, .MuiInput-underline:active:before {
      border: none;
    }
  }

  .item-inner {
    padding: 0;
  }

  .bank-selector-container {
    --padding-start: 0;
  }

  .display-inline-flex {
    display: inline-flex;
    width: 100%;
  }

  .display-flex {
    display: flex;
  }
  
  .display-none {
    display: none;
  }

  .input-container {
    --padding-start: 0;
    --inner-padding-end: 0;
    font-size: 14px;
    --background-hover: transparent;
    --highlight-color-focused: #3f90b0;
    --highlight-height: 0;
    --ion-color-primary: #3f90b0;

    ion-label {
      white-space: normal !important;
      padding-right: 30px;

      ion-icon, a ion-icon {
        position: absolute;
        bottom: 3px;
      }

      a ion-icon {
        bottom: 14px;
        margin-left: 10px;
      }
    }

    ion-select, .native-input {
      --padding-bottom: 0;
    }

    .in-item {
      margin: 0;
    }

    ion-checkbox {
      --border-width: 2px;
      --border-color: #3f90b0;
      --background-checked: #3f90b0;
      --border-color-checked: #3f90b0;
    }

    ion-select {
      --padding-start: 0;
      max-width: 100%;
      width: 100%;
    }
  }
}
